// Gatsby supports TypeScript natively!
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Panel from '../components/panel';
import LinkOut from '../components/linkOut';
import TopPanel from '../components/topPanel';
import ButtonLink from '../components/buttonLink';

import HorizontalTitle from '../components/horizontalTitle';
import HorizontalButtonsContainer from '../components/horizontalButtonsContainer';
import VerticalButtonsContainer from '../components/verticalButtonsContainer';

import Map from '../images/Multi-Scale Integrated Cell (MuSIC) v1.png';
import styles from './pageStyles/index.module.css';
import pipeline from '../images/pipeline_overview.webp';
import RF from '../images/GitHub_RandomForest.png';
import cells from '../images/cells.jpeg';


const contrastBackgroundColor = 'rgba(65,182,255, 0.25)';
const contrastTextColor = 'black';

const DonwloadData = (props: PageProps) => (
  <Layout>
    <SEO title='Tools' />
   
    <TopPanel>
      <HorizontalTitle title='MuSIC Project' subtitle='Browse MuSIC Tools' />

    </TopPanel>

    <Panel   
    >
      <a id='data'>
        <h2>MuSIC Tools</h2>
      </a>
      <ul> This page contains links to the various tools used to construct MuSIC maps. This page will be updated as new MuSIC maps and associated pipelines are released.
      </ul>
    </Panel>    
    <Panel  backgroundColor={contrastBackgroundColor}
      textColor={contrastTextColor}
    >
      <a id='data'>
        <h2>Tools for MuSIC map construction </h2>
      </a>
      <p>
      <div className={styles.logosContainer}>
          <img src={pipeline} />
      </div>
        <ul>          
          <li>
          MuSIC map pipeline
          <ol>   This package is used to invoke steps of the MuSIC map construction pipeline, including downloading data, generating embeddings, unsupervised co-embedding, and creating the final MuSIC map hierarchy. </ol> 
          <ol>
            <LinkOut link='https://github.com/idekerlab/cellmaps_pipeline'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-pipeline.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
         <ul>          
          <li>
          Image downloader
          <ol>   This package downloads images from the Human Protein Atlas or other user-supplied links </ol> 
          <ol>
          <LinkOut link='https://github.com/idekerlab/cellmaps_imagedownloader'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-imagedownloader.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
         <ul>          
          <li>
          PPI downloader
          <ol>   This package loads in a protein-protein interaction network and prepares the data for embedding  </ol> 
          <ol>
          <LinkOut link='https://github.com/idekerlab/cellmaps_ppidownloader'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-ppidownloader.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
         <ul>          
          <li>
          Generate image embedding
          <ol>   This package creates an image embedding using the <LinkOut link='https://www.nature.com/articles/s41592-019-0658-6'> densenet</LinkOut> model (see Tools for image embedding section below)  </ol> 
          <ol>
          <LinkOut link='https://github.com/idekerlab/cellmaps_image_embedding'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-image-embedding.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
         <ul>          
          <li>
          Generate PPI embedding
          <ol>   This package creates an embedding for each protein in a protein-protein interaction netowrk using <LinkOut link='https://cellmaps-utils.readthedocs.io/en/latest/'> node2vec</LinkOut>  </ol> 
          <ol>
          <LinkOut link='https://github.com/idekerlab/cellmaps_ppi_embedding'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-ppi-embedding.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
         <ul>          
          <li>
          Co-embedding
          <ol>   This package uses unsupervised machine learning to integrate the image and PPI embeddings into a single co-embedding for each protein based on an implementation of <LinkOut link='https://github.com/AltschulerWu-Lab/MUSE'> MUSE</LinkOut>  </ol> 
          <ol>
          <LinkOut link='https://github.com/idekerlab/cellmaps_coembedding'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-coembedding.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
         <ul>          
          <li>
          Generate Hierarchy
          <ol>   This package constructs a hierarchy using pan-resolution community detection with <LinkOut link='https://github.com/fanzheng10/HiDeF'> HiDeF</LinkOut>  </ol> 
          <ol>
          <LinkOut link='https://github.com/idekerlab/cellmaps_generate_hierarchy'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-generate-hierarchy.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
         <ul>          
          <li>
          Cell maps utils
          <ol>   This package contains utilities needed by the various tools </ol> 
          <ol>
          <LinkOut link='https://github.com/idekerlab/cellmaps_utils'> GitHub
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://cellmaps-utils.readthedocs.io/en/latest/'> Documentation
             </LinkOut>
          </ol>
          </li>
         </ul>
      </p>
    </Panel>

    <Panel
    >
      <a id='data'>
        <h2>Tools for image embedding</h2>
      </a>
      <p>
      <div className={styles.logosContainer}>
          <img src={cells} style={{ width: '25vw', minWidth: '10em' }} />
      </div>
        <ul>          
          <li>
          Image embedding
          <ol>   This GitHub repository contains code for running the winning model from the Human Protein Atlas Kaggle challenge (<LinkOut link='https://www.nature.com/articles/s41592-019-0658-6'>Ouyang et al., Nature Methods 2019</LinkOut>) </ol> 
          <ol>
            <LinkOut link='https://github.com/CellProfiling/densenet/tree/v0.1.0'> GitHub
             </LinkOut>
          </ol>
          </li>
         </ul>
         <ul>          
          <li>
          Single cell embedding
          <ol>   This GitHub repository contains code for running the winning model from the Human Protein Atlas Single-Cell Classification Kaggle challenge (<LinkOut link='https://www.nature.com/articles/s41592-022-01606-z'>Le et al., Nature Methods 2022</LinkOut>) </ol> 
          <ol>
            <LinkOut link='https://github.com/CellProfiling/densenet/tree/v0.1.0'> GitHub
             </LinkOut>
          </ol>
          </li>
         </ul>
      </p>
    </Panel>


    <Panel backgroundColor={contrastBackgroundColor}
      textColor={contrastTextColor}
    >
      <a id='data'>
        <h2>Tools for HEK293 MuSIC Map v1.0</h2>
      </a>
      <p>
      <div className={styles.logosContainer}>
          <img src={RF} style={{ width: '25vw', minWidth: '10em' }} />
      </div>
        <ul>          
          <li>
          MuSIC v1.0 map construction
          <ol>   This GitHub repository contains code and a step-by-step guide for constructing MuSIC maps using the v1.0 pipeline (<LinkOut link='https://doi.org/10.1038/s41586-021-04115-9'>Qin et al., Nature 2021</LinkOut>), which includes supervised machine learning trained with the Gene Ontology.  </ol> 
          <ol>
            <LinkOut link='https://github.com/idekerlab/MuSIC'> GitHub
             </LinkOut>
          </ol>
          </li>
         </ul>
      </p>
    </Panel>

  </Layout>
);

export default DonwloadData;
